<template>
  <div class="wh-space-bottom">
    <b-card>
      <table-search
        ref="search"
        v-model="filterValue"
        :fields="search_fields"
        @runSearch="runSearch"
        @changeField="searchChangeField"
      />
    </b-card>

    <b-card>
      <table-header
        ref="header"
        :title="title"
        :button-actions="header_actions"
        @runSearch="runSearch"
        :hide-search="false"
        :search-tips="$t('kiosk.search_tips')"
      />
      <table-content
        :per-page="page_condition.pageSize"
        :per-page-dropdown-enabled="true"
        @perPageChange="perPageChange"
        :columns="columns"
        :rows="rows"
        :total-rows="total_rows"
        :current-page="current_page"
        :action-options="table_actions"
        :permission="view_permission"
        @runTableAction="runTableAction"
        @sortChange="sortChange"
        @pageChange="pageChange"
      />
    </b-card>
  </div>
</template>

<script>
import common from '@/common'
import TableContent from '@/components/TableContent'
import TableHeader from '@/components/TableHeader'
import TableSearch from '@/components/TableSearch'
import kioskUtils from '@/libs/kiosk-utils'

export default {
  name: 'kioskEnvironment',
  components: {
    TableSearch,
    TableHeader,
    TableContent,
  },
  data() {
    return {
      title: common.getMenuName('kioskEnvironment'),
      view_permission: common.checkPermission('Kiosk_Menu_Environment'),
      // view_permission: true,
      search_fields: [
        {
          field: 'kioskEnvironment',
          label: 'kiosk.device_env',
          type: 'select',
          options: []
        },
        {
          field: 'kioskPlatform',
          label: 'kiosk.device_platform',
          type: 'select',
          options: []
        },
        {
          field: 'isRegistered',
          label: 'kiosk.registered',
          type: 'select',
          options: []
        },
      ],
      filterValue: {},
      header_actions: [
        {
          text: 'common.export_device_list',
          variant: 'primary',
          fun: this.exportAllData,
          permission: 'Kiosk_Menu_Environment'
        },
        {
          text: 'common.add',
          variant: 'primary',
          fun: this.addData,
          permission: 'Kiosk_Menu_Environment'
        },
      ],
      rows: [],
      total_rows: 0,
      sort_condition: {},
      current_page: 1,
      page_condition: {
        pageIndex: 0,
        pageSize: common.getTablePerPage()
      },
      columns: [
        {
          label: 'common.row_index',
          field: 'row_index',
          sortable: false,
          width: '50px',
        },
        {
          label: 'kiosk.device_token',
          field: 'kioskToken',
          width: '180px',
          changeHTML: (value, row) => `<span class="wh-font-family-monospace">${value}</span>`
        },
        {
          label: 'kiosk.device_name',
          field: 'kioskName',
          width: '280px',
        },
        {
          label: 'kiosk.device_env',
          field: 'kioskEnvironment',
          width: '120px',
        },
        {
          label: 'kiosk.device_platform',
          field: 'kioskPlatform',
          width: '120px',
        },
        {
          label: 'kiosk.registered',
          field: 'isRegistered',
          width: '90px',
          changeHTML: (value, row) => value ? `<span>${this.$t('common.yes')}</span>` : `<span>${this.$t('common.no')}</span>`,
        },
        {
          label: 'common.action',
          field: 'action',
          width: '200px',
          sortable: false,
        },
      ],
      table_actions: [
        {
          text: 'common.edit',
          variant: 'primary',
          fun: 'editData',
          permission: 'Kiosk_Menu_Environment'
        },
        {
          text: 'common.delete',
          variant: 'danger',
          fun: 'deleteData',
          permission: 'Kiosk_Menu_Environment'
        }
      ],
    }
  },

  activated() {
    this.getList()
    kioskUtils.getKioskEnvOptions().then(({ envOptions, platformOptions, registerOptions }) => {
      this.search_fields.find(f => f.field === 'kioskEnvironment').options = envOptions
      this.search_fields.find(f => f.field === 'kioskPlatform').options = platformOptions
      this.search_fields.find(f => f.field === 'isRegistered').options = registerOptions
    })
  },
  deactivated() {
    this.$refs.search.resetFilterValueToFiltered()
  },

  methods: {
    getList: function () {
      if (!this.view_permission) return

      this.$requestWehealth({
        method: 'get',
        url: '/kioskmanage/GetKioskList',
        params: Object.assign({}, this.getSearchCondition(), this.sort_condition, this.page_condition),
      })
        .then(res => {
          this.total_rows = res.data.total
          this.rows = res.data.kiosks
        })
    },
    perPageChange(size) {
      this.page_condition.pageIndex = 0
      this.page_condition.pageSize = size
      common.setTablePerPage(size)
      this.getList()
    },
    exportAllData() {
      common.apiDownloadData({
        url: '/kioskmanage/ExportKioskList',
      })
    },
    runSearch() {
      this.current_page = 1
      this.page_condition.pageIndex = 0
      this.getList()
    },
    searchChangeField() {
    },
    getSearchCondition: function () {
      this.$refs.search.buildSearchCondition()

      let where = {}
      where['searchContent'] = this.$refs.header.searchContent
      where = Object.assign(where, this.$refs.search.search_condition)

      return where
    },
    addData: function () {
      this.$router.push({ name: 'kioskEnvironment_add' })
    },
    editData: function (data) {
      if (data.isUAT == null) {
        data.isUAT = ' '
      }
      this.$router.push({
        name: 'kioskEnvironment_edit',
        params: {
          token: common.encrypt(data.kioskToken),
        }
      })
    },
    deleteData: function (data) {
      common.showConfirm({
        title: this.$t('common.delete_tips') + data.kioskToken,
        confirm_fun: async () => {
          await this.$requestWehealth({
            url: '/kioskmanage/DeleteKiosk',
            method: 'post',
            params: {
              kioskToken: data.kioskToken
            }
          })
          common.showToast({ title: this.$t('common.delete_success') })
          this.getList()
        }
      })
    },
    runTableAction: function (action, data) {
      this[action](data)
    },
    sortChange: function (value) {
      this.sort_condition = value
      this.getList()
    },
    pageChange: function (value) {
      this.current_page = value
      this.page_condition.pageIndex = value - 1
      this.getList()
    },
  }
}
</script>
